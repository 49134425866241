import Web3 from 'web3';
import { ethers, parseUnits } from 'ethers';

let selectedAccount;
let erc20Contract, erc721Contract;
let isInitialized = false;

export const init = async () => {
	let provider = window.ethereum;

	if (typeof provider !== 'undefined') {
		provider
			.request({ method: 'eth_requestAccounts' })
			.then((accounts) => {
				selectedAccount = accounts[0];
				console.log(`Selected account is ${selectedAccount}`);
			})
			.catch((err) => {
				console.log(err);
				return;
			});

		window.ethereum.on('accountsChanged', function (accounts) {
			selectedAccount = accounts[0];
			console.log(`Selected account changed to ${selectedAccount}`);
		});
	}

	const web3 = new Web3(provider);
	const networkId = await web3.eth.net.getId();

	//{"inputs":[{"internalType":"address","name":"account","type":"address"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
	const erc20Abi = [
		{"inputs":[{"internalType":"address","name":"account","type":"address"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
		{"inputs":[{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"burn","outputs":[],	"stateMutability":"nonpayable",	"type":"function"},	
		{"inputs":[{"internalType":"address","name":"account","type":"address"},{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"burnFrom",	"outputs":[],"stateMutability":"nonpayable","type":"function"}
	];

	const erc721Abi = [
		{"inputs":[{"internalType":"uint256","name":"quantity_","type":"uint256"}],"name":"mint","outputs":[],"stateMutability":"payable","type":"function"},
		{"inputs":[],"name":"mintPrice","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}
	];

	erc20Contract = new web3.eth.Contract(
		erc20Abi,
		// CONK contract 
		'0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E'
	);

	erc721Contract = new web3.eth.Contract(
		erc721Abi,
		// CONKNFT contract 
		'0x0f1c88265C73CacBCb2637299C0e796e03e81C81'
	);


	isInitialized = true;
};

export const getOwnBalance = async () => {
	console.log('Is Initialized?'+isInitialized);
	if (!isInitialized) {
		await init();
	}

	console.log('After Initialized?'+isInitialized);
	return erc20Contract.methods
		.balanceOf(selectedAccount)
		.call()
		.then((balance) => {
			return Web3.utils.fromWei(balance);
		});
};

export const mintPrice = async (e) => {
	let amt;
	console.log("Entering Mint Price");
	amt =  erc721Contract.methods.mintPrice.call();
	console.log("Exiting Mint Price: "+amt);
	return amt;
}

export const mintCONKNFT = async (e) => {

	if (!isInitialized) {
		await init();
	}
	console.log('Successful Mint Init.');


	let provider = window.ethereum;


	const web3 = new Web3(provider);
	const chainId = await web3.eth.net.getId();
	//const chainId = await provider.getNetwork();
	console.log("chainId:"+ (chainId == 4002)); 
	if (chainId != 4002) {
		console.log("ERROR:"+chainId);
		return "{\"message\": \"Must must Fantom Testnet.\"}";
	}
	console.log("chainId:"+chainId); // 42

	let a = 0;
	let error = "";
	//const gasEstimate = await erc20Contract.methods.burn(e).gasEstimate;

	await erc721Contract.methods.mint(e).estimateGas({
		from: selectedAccount, gas: 3000000, value: e*1000000000000000000 
		})
		.then(function(gasAmount){
			console.log('***mint estimate:'+gasAmount);
			a = (gasAmount * 1.15).toFixed(0);
		
		}).catch((err) => {
		console.log("Error in Mint Est:"+err);
		error = err.message;
	});

	console.log('mint estimate:'+a);
	console.log('mint parameter:'+e);
	console.log('error:'+error);

	if (error=="") {
		return await erc721Contract.methods.mint(e).send({
			from: selectedAccount, gas: a, value: e*1000000000000000000 
		}).catch((err) => {
			console.log("Error in Mint:"+err);
		});
	} else {
		return error;
	}

	/*
	.send({
            from: selectedAccount,
        }).catch((err) => {
			console.log("Error in Mint:"+err);
		});
*/

}

export const burnConk = async (e) => {

	//e = e.padEnd(19, 0);
	let i = 0;
	if (e.indexOf(".")>=0) {
		let s = e.substring(e.indexOf("."));
		i = s.length-1;
		e = e.replace(".", "");
	}
	console.log("characters after decimal:" + i);
	console.log("e:" + e);
	
	for (let x = i; x < 18; x++) {
		e = e + "0";
	}

	console.log("padding:"+e);
	console.log('Amount:'+e);

	if (!isInitialized) {
		await init();
	}
	console.log('Successful Init.');


	let provider = window.ethereum;
	//const gasPrice = await provider.getGasPrice();
	//console.log('gasPrice'+gasPrice);
	//const gasPriceInGwei = ethers.formatUnits(gasPrice, "gwei");
	//const currGas = gasPriceInGwei;
	//console.log('gasPrice'+currGas);
	//var estimate = Web3.eth.estimate( )

	let a = "";
	

	//const gasEstimate = await erc20Contract.methods.burn(e).gasEstimate;
	await erc20Contract.methods.burn(e).estimateGas({from: selectedAccount})
		.then(function(gasAmount){
			console.log('***estimate:'+gasAmount);
			a = (gasAmount * 1.15).toFixed(0);
	}).catch((err) => {
		console.log("Error in Est:"+err);
	});
    
	console.log('estimate:'+a);

	return erc20Contract.methods.burn(e)
		.send({
            from: selectedAccount,
			gas: a,
        }).catch((err) => {
			console.log("Error in Burn:"+err);
		});

};

const getGasAmountForContractCall = async (fromAddress, amount) => {
    //const contract = new Web3.eth.Contract(ABI, contractAddress);
    let gasAmount = await erc20Contract.methods.burn(Web3.utils.toWei(`${amount}`)).estimateGas({ from: fromAddress });
    return gasAmount;
}

export const getAccount = async () => {
	if (!isInitialized) {
		await init();
	}

	return selectedAccount;
};