import React from 'react';
import Accordion from '../components/accordion/Accordion';
import '../components/accordion/styles/accordion.css';

const FAQ = () => {
  return (
    <div
      style={{
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center'
     }}
    > 
      <Accordion />
    <br></br><br></br>
    </div>

  );
};

export default FAQ;
