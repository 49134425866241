import React from 'react';
import './cards.css';
import CardItem from './CardItems';

function Cards() {
  return (
      <div style={{
        textAlign: 'left',
        paddingLeft: 25,
      }}>
              <h2 style={{
        fontSize: 40
      
      }}>Community Events</h2>
      
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>

          <CardItem
              src='images/conkta.png'
              label=''
              text='Limited release creations based on an analysis of the $CONK charts!'
              path='https://paintswap.finance/marketplace/fantom/collections/%24conk-ta'
            />
              <CardItem
              src='images/cartel.JPG'
              label=''
              text='$Conk Cartel is a collection of custom 1/1 NFTs that pay homage to $Conk x Pocong'
              path='https://nftkey.app/collections/conkcartel/?marketplaceTab=all'
              /> 
            <CardItem
            src='images/mint.JPG'
            label=''
            text='Upcoming mint for CONKers.  More details to come!'
            path=''
          />  

            </ul>         
            <ul className='cards__items'>
                <CardItem
              src='images/conkpunk.JPG'
              label='ConkPunks'
              text='25% of the revenue will go to CONKPUNK holders & CONK Treasury.  Check them out!'
              path='https://nftkey.app/collections/binaryconkpunks/'
            />
            <CardItem
              src='images/infinite.PNG'
              text='Community NFT with the aim of increasing liquidity for $CONK - 95% towards LP.'
              path='https://conkthanks.typedream.app/mint'
            />
            <CardItem
              src='images/rave.PNG'
              text='Get your own CONK domain name!  - 5 $FTM per name - 50/50 revenue split!'
              path='https://conk.rave.cyou/'
            />            
            </ul>         
            <ul className='cards__items'>
            <CardItem
              src='images/slim.PNG'
              text='$CONK celebration NFT raffle 75% airdrops-25% donated $CONK liquidity. 20 FTM ticket!'
              path='https://SlimJimmy420k.com'
            />

            <CardItem
              src='images/avaconk.PNG'
              text='AVACONK is a project initiated for the benefit of the $CONK and $FTM communities.'
              path='https://paintswap.finance/marketplace/fantom/collections/avaconk'
    />          
            <CardItem
              src='images/conkflip.JPG'
              text='Flip a coin double your $FTM! 50% of fees straight to $CONK liquidity!'
              path='https://conkflip.crosschainarcade.com'
    />          
    </ul> 
{/*
          <ul className='cards__items'>
          <CardItem
              src='images/slim.PNG'
              text='CONK celebration NFT raffle!'
              path='https://SlimJimmy420k.com'
            />

            <CardItem
              src='images/tombheads.PNG'
              text='Auction Sunday Feb 26th @ 9PM UTC'
              path='https://tombheads.com/'
            />

    </ul>*/}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Cards;