import React from 'react';
import { FaTwitter, FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { AiFillMail, AiOutlineBorder } from 'react-icons/ai';

const SubHeader = () => {
    return (
		<div className='social'>
			<table width="100%" border="0" style={{
        padding: '0',
      }}>
        <tr style={{
          marginTop: '0'
        }}>
      <td style={{
				width: '80%',
        color: 'white',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'cursive',
        fontStyle: 'italic',
        paddingLeft: 30,
			}}
			>

      </td>
			<td style={{
				marginLeft: 'auto',
				marginRight: '0',
				width: '20%',
        whiteSpace: 'nowrap'
			}}>
			<h2>
				<a href='https://twitter.com/shibapoconk' rel="noreferrer" target='_blank'><FaTwitter style={{
                    color : 'white'}}/></a>&nbsp;&nbsp;
				<a href='https://discord.gg/9Kgqt9mTcH' rel="noreferrer" target='_blank'><FaDiscord style={{
                    color : 'white'}}/></a>&nbsp;&nbsp;
				<a href='https://t.me/CONKFTM' rel="noreferrer" target='_blank'><FaTelegramPlane style={{
                    color : 'white'}}/></a>&nbsp;&nbsp;
				<a href='mailto:team@conkme.com' rel="noreferrer" target='_blank'><AiFillMail style={{
                    color : 'white'}}/></a> 
			</h2>				
				</td></tr></table>
</div>
    );
  };
  
  export default SubHeader;