import React from 'react';
import Navbar from "./components/Navbar";
import { FooterContainer } from './containers/footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import About from './pages/about'
import Multi from './pages/multi'
import FAQ from './pages/faq'
import Home from './pages/index'
import Cards from './pages/cards';
import SubHeader from './components/subheader';
import News from './pages/news';
import Burn2 from './pages/burnbeta2';
import Mint from './pages/mint';

const App = () => {

return (
	<div>	
	<Navbar/>
	<SubHeader/>
      <Router>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/about' element={<About />} />
				<Route path='/multi' element={<Multi />} />
				<Route path='/faq' element={<FAQ />} />
				<Route path='/cards' element={<Cards />} />
				<Route path='/burn' element={<Burn2 />} />
				<Route path='/mint' element={<Mint />} />
			</Routes>
		</Router>
		<div className="app">
			<FooterContainer /> 
		</div>
    </div>
)
};

export default App;