import React, { Component } from "react";
import Slider from "react-slick";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import './slider.css'

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      autoplaySpeed: 5000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,      
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            width: '90%'
          }
        },
        {
          breakpoint: 851,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            width: '87%'
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };

    return (
      <div style={{
        paddingBottom: 30
      }}>

      
      
      <br></br>
      <div style={{
        textAlign: 'left',
        paddingLeft: 25,
      }}>
      <h3>Updates</h3>
      <h2 style={{
        fontSize: 40
      
      }}>CONK News</h2>
      </div>
      <center>
        <Slider {...settings} style={{
            width: '87%'
        }}>

<div>
          <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/week.png"></img>
            </td><td className="tdImg" style={{
              height: '10vw',
              objectFit: 'contain',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            CONK Latest News<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 20,
              fontWeight: 'bold',
            }}>Check out all the CONK happenings in our highlights!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="/news" endIcon={<OpenInNewIcon />}>
            CONK News
            </Button></center>

            </td></tr></table>
</div>


<div>
          <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/8play.JPG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            CONK 8Play Gaming<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
            }}>Conk Runners is now LIVE! Play here! Submit scores in discord!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://www.8play.games/" target="_blank" endIcon={<OpenInNewIcon />}>
            8Play Games
            </Button></center>

            </td></tr></table>

          </div>






          <div>
            <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/skull.JPG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
           New Partnership<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
            }}>Check out the new CONK-FTM V3 pool over at SkullSwap!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://www.skullswap.exchange/" target="_blank" endIcon={<OpenInNewIcon />}>
            Skullswap
            </Button></center>

            </td></tr></table>

          </div>

          <div>
          <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/magik.JPG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            New Partnership<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
            }}>Mint your $Conk Faction to join the World of Magik wars!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://www.conkfaction.worldofmagik.com/" target="_blank" endIcon={<OpenInNewIcon />}>
            Magik
            </Button></center>

            </td></tr></table>

          </div>

          <div>
            <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/multi.JPG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            March 1st, 2023<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
            }}>CONKlave MultiSig wallets are setup. 100% community.</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="/multi" endIcon={<OpenInNewIcon />}>
            MultiSig Info
            </Button></center>

            </td></tr></table>

          </div>

          <div>
          <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/equal-slider.jpg"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            Wednesday 12am UTC<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
            }}>Get your Bribes & Votes in at Equalizer Exchange!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://equalizer.exchange/" target="_blank" endIcon={<OpenInNewIcon />}>
            Equalizer
            </Button></center>

            </td></tr></table>

          </div>

        
          <div>
          <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/beets.PNG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
              }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            Every two weeks!<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 18,
              fontWeight: 'bold',
            }}>Vote Fugue Pool in the Gauge Vote for Beets emmissions!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://beets.fi/pool/0x4707eed23f628de1a032235158e33bc3c9fcf2f4000100000000000000000661" target="_blank" endIcon={<OpenInNewIcon />}>
            BeethovenX
            </Button></center>

            </td></tr></table>
</div>

{/*
<div>
<table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/matrix.JPG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,            }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            Yield Optimizer<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 20,
              fontWeight: 'bold',
            }}>The easiest yield optimizer with massive CONK APRs!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://matrix.farm/" target="_blank" endIcon={<OpenInNewIcon />}>
            Matrix.Farms
            </Button></center>

            </td></tr></table>

          </div> 
 
          <div>
          <table border="0" style={{
                maxWidth: 525, 
                height: 190, 
                maxHeight: 190, 
                borderRadius: 15
            }}>
              <tr><td style={{
              height: 190,
              maxWidth:200,
              objectFit: 'contain',
            }}>
            <img style={{
              height: '85vw',
              width: '40vw',
              maxWidth:200,
              maxHeight:200,
              borderBottomLeftRadius: '25px',
              borderTopLeftRadius: '25px',
              zIndex: 200,
            }} src="./images/grim.JPG"></img>
            </td><td style={{
              height: '10vw',
              maxWidth: '350',
              maxHeight: '190px',
              textAlign: 'left',
              backgroundColor: '#2e2c2c',
              borderBottomRightRadius: '25px',
              borderTopRightRadius: '25px',
              paddingLeft: 25,
                          }}>
            <font style={{
              color: 'grey',
              fontSize: 15,
              paddingBottom: 15
            }}>
            Yield Optimizer<br></br>
            </font>
            <font style={{
              paddingTop: 10,
              fontSize: 20,
              fontWeight: 'bold',
            }}>Juicy APRs to be had with our friends at Grim Finance!</font><br></br><br></br>
            <center style={{
              marginLeft: '0',
              marginRight: 'auto'
            }}><Button size="small" variant="outlined" href="https://app.grim.finance/" target="_blank" endIcon={<OpenInNewIcon />}>
            Grim.Finance
            </Button></center>

            </td></tr></table>

          </div> 
          */}
 
         </Slider>
        </center>
      </div>
    );
  }
}