import { useEffect, useState } from "react";
import { getOwnBalance, getAccount, burnConk } from './Web3Client';
import {AiOutlineFire} from 'react-icons/ai';
import {CiWallet} from 'react-icons/ci';
import { Button } from '@mui/material';
import homeStore from "../stores/homeStore";

//import "./App.css";

function App() {
	const store = homeStore();
	const [walletAddress, setWalletAddress] = useState("");
  	const [balance, setBalance] = useState(0);
  

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
  }, [walletAddress]);



	async function burnAmt(e) {
		let amt = document.getElementsByName("burn_amount")[0].value;
		if (amt=="" || amt=="0") return;
		console.log("Entered the burn:"+amt);

		await burnConk(amt).then({
			fetchBalance
		}).catch((err) => {
			console.log("Error:"+err);
		});
		
		console.log("Finished the burn");
	}

	function setMax() {
		document.getElementsByName('burn_amount')[0].value = document.getElementsByName('max')[0].value;
	}

  const connectWallet = async () => {
	console.log("entering");
	if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* MetaMask is installed */
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
		fetchBalance();
        console.log(accounts[0]);
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const getCurrentWalletConnected = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
		  fetchBalance();
          console.log(accounts[0]);
        } else {
          console.log("Connect to MetaMask using the Connect button");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts[0]);
		fetchBalance();
        console.log(accounts[0]);
      });
    } else {
      /* MetaMask is not installed */
      setWalletAddress("");
      console.log("Please install MetaMask");
    };
  };
	const fetchBalance = async () => {
		getOwnBalance()
			.then((balance) => {
				setBalance(balance);
			})
			.catch((err) => {
				console.log(err);
			});
       
			console.log("ACCOUNT:"+walletAddress);
	};


  return (
    <div style={{
		margin: "auto auto",
		textAlign: 'center',
	}}>
		<center>
		<br></br><br></br><br></br>
		<div style={{
			width: '50%',
			minWidth: 375,
			backgroundImage: "url('./images/burn.png')",
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100%',
			textAlign: 'center',
			marginTop: '0px',
			padding: '10px',
			borderRadius: '15px',
			webkitBoxShadow: '0 0 5px 1px #da575d',
			}}>
	<p style={{
		fontSize: '2.5vmax'
	}}>
	Total $CONK Burned <br></br>
	<font style={{
		fontSize: '4.5vmax',
		fontWeight: 'bolder',
	}}>{store.coins.burn}</font>
	<br></br>
	${store.coins.burnprice}
	</p>
			</div>
		<div style={{
			backgroundColor: '',
			textAlign: 'center',
		}}>
<br></br><br></br>

                <span>
                  {walletAddress && walletAddress.length > 0
                    ? `Wallet Connected: ${walletAddress.substring(
                        0,
                        6
                      )}...${walletAddress.substring(38)}`

				 : <div>To Burn some $CONK, please connect your wallet.  &nbsp;&nbsp;
				 <Button size="small" variant="contained" 
				 style={{
					 borderRadius: 35,
					 backgroundColor: "#da575d",
					 fontSize: "12px"
				 }}				
				 onClick={() =>  connectWallet()} 
				 endIcon={< CiWallet/>} > 
				 Connect 
			 </Button></div>
				 }
                </span>
			  { (walletAddress && walletAddress.length > 0) ?
			  <div> 
			  	<input type="text" name="burn_amount" size="40" placeholder='Burn Amount:' /> &nbsp;&nbsp;
								<Button size="small" variant="contained" 
						style={{
							borderRadius: 35,
							backgroundColor: "#da575d",
							fontSize: "12px"
						}}				
						onClick={() =>  burnAmt()} 
						endIcon={< AiOutlineFire/>} > 
						Burn 
					</Button>
					<font style={{
						fontSize: '11px',
					}}>
					<p onClick={() =>  setMax()} style={{
						cursor: 'pointer'
					}}>
					<input type="hidden" name="max" value={balance} />
					MAX: &nbsp;&nbsp;&nbsp;{balance}</p> <br></br></font>
		
					***This action will burn your CONK forever!***
					<br></br>
					<a className="alink" href="https://ftmscan.com/token/0xb715f8dce2f0e9b894c753711bd55ee3c04dca4e?a=0x0000000000000000000000000000000000000000" target="_blank">View Burns on FTMSCAN</a>
					</div>
					: ""}
		<br></br><br></br><br></br><br></br><br></br>			  
		</div>
		</center>
	</div>
  );
}

export default App;