import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, 
  TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, 
  TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import CardsTeam from './cards-team';
import {AiOutlineCheckSquare, AiOutlineQuestionCircle} from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { textAlign } from '@mui/system';
import './news.css';


const Multi = () => {
  return (
    <div
      style={{
        marginTop: 15,
        marginBottom: 30
      }}
    >
      <center>


      <CardsTeam></CardsTeam>
      <div style={{
      width: '38vw',
      minWidth: '350px',
      textAlign: 'left',
      padding: 1,
      opacity: '75%',
      borderRadius: 15,
      borderStyle: 'groove',
      borderWidth: 'thin',
      borderColor: 'white',
      backgroundColor: '#da575d'
    }}>
    
    
<center>
  <table border="0" width="100%"><tr><td style={{
    textAlign: 'center'
  }}>  <h2>Mutlisig Wallets:</h2></td><td>
  <div style={{
      alignItems: 'right',
      textAlign: 'right',
      paddingRight: 10,
      width: '100%'
    }}><a class="alink" href='https://docs.fantom.foundation/staking/fantom-safe' target="_blank"><AiOutlineQuestionCircle></AiOutlineQuestionCircle></a></div>
    </td></tr></table>

<table border="0" style={{
  padding: 5,
  width: '100%',
}}><tr><td style={{
  whiteSpace: 'nowrap',
  verticalAlign: 'top',
}}>
    Treasury:<br></br>
</td><td style={{
      padding: 0,
      textAlign: 'right',
      wordBreak: 'break-word',
    }}>
<a class="alink" href="https://ftmscan.com/address/0x405D0777659549D78428ff1FD73cd16B25d9463A" target={"_blank"}>0x405D0777659549D78428ff1FD73cd16B25d9463A</a>

      </td></tr><tr><td style={{
          whiteSpace: 'nowrap',
  verticalAlign: 'top',
      }}>
        DEFI: </td><td style={{
          padding: 0,
          textAlign: 'right',
          wordBreak: 'break-word',
          }}>
        <a class="alink" href="https://ftmscan.com/address/0x9b2a62371c530fd357b9d59dec1622599a4a1bbb" target={"_blank"}>0x9b2A62371C530fd357B9d59DeC1622599A4a1BBB</a>     
        </td></tr></table>
      </center>
    </div>
    
    <br></br>
    {/*}

        <a class="alink" href="https://ftmscan.com/address/0x405D0777659549D78428ff1FD73cd16B25d9463A" target={"_blank"}>0x9b2A62371C530fd357B9d59DeC1622599A4a1BBB</a>     
        */}
    <div style={{
      width: '60%',
      minWidth: '335px',
      padding: 15,
      opacity: '75%',
      borderRadius: 15,
      borderStyle: 'groove',
      borderWidth: 'thin',
      borderColor: 'white',
      backgroundColor: '#da575d',
      backgroundRepeat: 'no-repeat',
    }}>
      <h3>$CONK transition process to a fully "community-owned project".</h3>
      <br></br>

      Artist's Seven Instruction No. 1 &nbsp;&nbsp;<a class="alink" href="https://ftmscan.com/tx/0xa4bc653ba5c2d2b6c507f7b66d0b38cae929c124f38558da89ced3e0741086bd" target={'_blank'}>[View on blockchain]</a><br></br>
      Artist's Seven Instruction No. 2 &nbsp;&nbsp;<a class="alink" href="https://ftmscan.com/tx/0x324b52859311d484e66cbca6687d397a17e9d41d08b6fc0b4d5be9aaeeddd259" target={'_blank'}>[View on blockchain]</a><br></br>
      Artist's Seven Instruction No. 3 &nbsp;&nbsp;<a class="alink" href="https://ftmscan.com/tx/0xfa319b0b0757c2e71af661cf32c9633b918dc9aa3b39d4123b4d0887b43ff500" target={'_blank'}>[View on blockchain]</a><br></br>
      Artist's Seven Instruction No. 4 &nbsp;&nbsp;<a class="alink" href="https://ftmscan.com/tx/0x808503f7dc8cb2a58d5795c2ac626f4c0910010498b4038dcaa805a3bdc01c13" target={'_blank'}>[View on blockchain]</a><br></br>
      Artist's Seven Instruction No. 5 &nbsp;&nbsp;<a class="alink" href="https://twitter.com/fantompocong/status/1630516335552897024" target={'_blank'}>[View on blockchain]</a><br></br>
      Artist's Seven Instruction No. 6 &nbsp;&nbsp;<a class="alink" href="https://twitter.com/fantompocong/status/1630973393679028225" target={'_blank'}>[View on blockchain]</a><br></br>
      Artist's Seven Instruction No. 7 &nbsp;&nbsp;<a class="alink" href="https://twitter.com/fantompocong/status/1630974279507644420" target={'_blank'}>[View on blockchain]</a><br></br>
      
      </div>
      </center>

    </div>
  );

/*
<TwitterTweetEmbed
      onLoad={function noRefCheck(){}}
      tweetId="1627597734332203008"
      options={{
        height: 300,
        width: 600
        }}
/>
*/
};

export default Multi;
