import React from 'react';

function NewFTM() {
  return (
    <div style={{
      verticalAlign: 'top',
      backgroundColor: '',
    }}>
      <h1>New to FTM ?</h1>

<div>

<table style={{
      paddingTop: 25,
      paddingBottom: 45,
      width: '85%',
      marginLeft: 'auto',
      marginRight: 'auto'

}}><tr><td><img style={{
width:325,
}}
src="./images/bridge.png"></img>
</td><td style={{
  width: '75%'
}}>
SpookySwap lets you transfer your assets from one chain to an other. The bridge uses Multichain technology on the back end. This is done by transferring and converting your tokens to the same wallet address, but on the Fantom network. 
Go to <a href="https://spookyswap.finance/bridge" target="_blank">https://spookyswap.finance/bridge</a>. 
Choose the network and the token you want to transfer from the list.<br></br>
Input the amount you want to transfer.  Confirm transaction. 
Once confirmation is received, connect to Fantom's network, you tokens will be displayed. If they are not displayed, you need to add them manually to your Metamask : Go to https://ftmscan.com/ and look for the contract address of the token you transferred. You can also add tokens via the swap interface. 
  </td></tr></table>

</div>
    </div>
  );
}

export default NewFTM;