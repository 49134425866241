import React from 'react';
import './cards.css';
import CardItem from './CardItems';

function CardsTeam() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src='images/bobby.PNG'
              text='@CryptoBobby86'
              label=''
              path='https://twitter.com/CryptoBobby86'
            />
            <CardItem
              src='images/hicksticks.png'
              text='.@hicksticks'
              label=''
              path='https://twitter.com/ftmlifer'
            />
            <CardItem
              src='images/pocong.PNG'
              text='@fantompocong'
              label=''
              path='https://twitter.com/fantompocong'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsTeam;