import { useEffect, useState } from "react";
import { mintCONKNFT, mintPrice, getAccount, burnConk } from './Web3Client';
import {AiOutlineFire} from 'react-icons/ai';
import {CiWallet} from 'react-icons/ci';
import { Button } from '@mui/material';

function App() {
	const [walletAddress, setWalletAddress] = useState("");
	const [error, setError] = useState("");
  const [price, setPrice] = useState(0);
  

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
    //getMintPrice();
  }, [walletAddress]);

  async function getMintPrice() {
    setPrice(mintPrice());
  }

  async function mint(e) {
    setError("");
    let amt = document.getElementsByName("mint_amount")[0].value;
		if (amt=="" || amt=="0") return;
		console.log("Minting... "+amt);

		await mintCONKNFT(amt).then((e) => {
			console.log("any error?:"+e);
      e = e.replace("Internal JSON-RPC error.", "");
      let jokes = JSON.parse(e)
      console.log("jokes:"+jokes);
      console.log("message:"+jokes.message);
      setError(jokes.message);
			}).catch((err) => {
			console.log("Error:"+err);
		});
		
		console.log("Finished Minting");
	}


  const connectWallet = async () => {
	console.log("entering");
	if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* MetaMask is installed */
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
        console.log(accounts[0]);
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const getCurrentWalletConnected = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          console.log(accounts[0]);
        } else {
          console.log("Connect to MetaMask using the Connect button");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts[0]);
        console.log(accounts[0]);
      });
    } else {
      /* MetaMask is not installed */
      setWalletAddress("");
      console.log("Please install MetaMask");
    };
  };

  return (
    <div style={{
		textAlign: 'center',
	}}>
<br></br><br></br><br></br><br></br>
Let's Mint!<br></br><br></br>
        <span>
          {walletAddress && walletAddress.length > 0
            ? `Wallet Connected: ${walletAddress.substring(
                0,
                6
              )}...${walletAddress.substring(38)}`

				 : <div>To min a CONKNFT, please connect your wallet.  &nbsp;&nbsp;
				 <Button size="small" variant="contained" 
				 style={{
					 borderRadius: 35,
					 backgroundColor: "#da575d",
					 fontSize: "12px"
				 }}				
				 onClick={() =>  connectWallet()} 
				 endIcon={< CiWallet/>} > 
				 Connect 
			 </Button></div>
				 }
                </span>
			  { (walletAddress && walletAddress.length > 0) ?
			  <div> 

          Mint Price 1 FTM <br></br><br></br>

			  	<input type="text" name="mint_amount" size="40" placeholder='Mint Amount:' /> &nbsp;&nbsp;
								<Button size="small" variant="contained" 
						style={{
							borderRadius: 35,
							backgroundColor: "#da575d",
							fontSize: "12px"
						}}				
						onClick={() =>  mint()} 
						endIcon={< AiOutlineFire/>} > 
						Mint 
					</Button>
		<br></br>
		<font style={{
      color: 'red'
    }}><b>{error}</b>	</font>

					<br></br>
					<a className="alink" href="https://testnet.ftmscan.com/address/0x0f1c88265C73CacBCb2637299C0e796e03e81C81" target="_blank">View Mint on FTMSCAN</a>
					</div>
					: ""}
	<br></br><br></br><br></br><br></br>
  </div>
  );
}

export default App;