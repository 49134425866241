import React from 'react';
import ImageSlider from '../ImageSlider';
import Cards from './cards';
import Hero from './hero';
import NewFTM from './newftm';
import SimpleSlider from './slider';
import SimpleSlider2 from './slider2';
import BasicDemo from './lists';

const Home = () => {

  return (
    <div>
      <Hero></Hero>
      <p class="caption" style={{
        width: '95%',
        textAlign: 'right',
        fontSize: '1.2vw',
        webkitFontSmoothing: 'antialiased',
      }}>CONK is a community led protocol, designed to bring fun, value and utility to the Fantom community.
      </p>
      <br></br><br></br><br></br><br></br><br></br>
    </div>
  );
  
/*
      <SimpleSlider></SimpleSlider>
      <br></br><br></br> 
      <Cards></Cards>
      <br></br>
*/
};

export default Home;
