import React from 'react';
import CardsPocong from './cards-pocong';
import './news.css';

const About = () => {
  return (
    <div
      style={{
        marginTop: 30,
        alignItems: 'center',
      }}
    >
      <center>
<table border="0" style={{
  width: "70%"
}}>
  <tr>
    <td style={{
      verticalAlign: 'top',
      marginTop: 80,
  }}>
      <div><br></br><br></br><br></br>
        <CardsPocong></CardsPocong>
      </div>
    </td>
    <td style={{
      width: '90%',
      minWidth: '100',
      verticalAlign: 'top',
    }}>
      <div style={{
        marginTop: 60,
        marginBottom: 20,
      }}>
  <h3>$CONK Manifesto &nbsp;&nbsp;<a class="alink" href="https://ftmscan.com/tx/0x87dedac6a60243d1e33e9d9798a8b59ef3a6444db9df616db772b52c8764944e" target="_blank"> [View on Blockchain]</a></h3>
  <br></br>
I’m Pocong. I’m an artist.<br></br>
I’m a meme creator,<br></br>
a blockchain experimenter.<br></br>
I’m a CONKer. <br></br><br></br>

$CONK is not a DeFi project.<br></br>
$CONK is an art project – in its truest sense.<br></br><br></br>

A painter uses paint, a filmmaker uses a camera.<br></br>
I use DApps and social-networking platforms.<br></br>
ERC-20 and DeFi tools are lil machines to bring my artistic vision to life.<br></br><br></br>

I do not build!<br></br>
I experiment, I innovate!<br></br>
I push the limits; I break the boundaries!<br></br>
I challenge the status quo.<br></br>

I reject the idea that blockchain-based projects are for the sake of money-making.<br></br>
I refuse to follow the conventional way within the tokenverse.<br></br>
I counter the crypto market and being critical to the meme economy.<br></br><br></br>

CONKers embrace diverse thoughts and opinions.<br></br>
Visionary CONKers celebrate the unknown and the unpredictable.<br></br>
Real CONKers stay.<br></br><br></br>

Community is dead.<br></br>
Long live Community.<br></br>
I persist in upholding my artistic vision.<br></br>
<br></br>
$CONK is mine.<br></br>
$CONK is yours.
</div>
</td></tr></table>
</center>
    </div>
  );
};

export default About;
