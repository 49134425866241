import React from 'react'
import Footer from '../components/footer'

export function FooterContainer() {
    return (
        <Footer>
            <Footer.Wrapper>
            <Footer.Row>
            <Footer.LeftColumn>
                <Footer.Title><img src={require('../CONK_LOGO.png')} style={{wdith: 30, width: 30}} alt='logo' /> &nbsp;ShibaPoCONK </Footer.Title>
                CONK is a community led protocol, designed to bring fun, value and utility to the Fantom community.
                    <a href="./images/CoinTool_Audit_Report_0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E.pdf" target={'_blank'} style={{
                        marginLeft : 'auto',
                        marginRight : 'auto',
                        marginTop: 'auto',
                    }}><img src="./images/audit.png"
                    style={{
                        borderRadius: 15,
                        width: 125,
                        height: 60
                    }}></img></a>
                </Footer.LeftColumn>
                <Footer.Column>
                <Footer.Title>NFT Friends</Footer.Title>
                    <Footer.Link href="https://wovven.io/conk" target="_blank">wovven.io</Footer.Link>
                    <Footer.Link href="https://paintswap.finance/marketplace/fantom/collections/%24conk-punks" target="_blank">CONK Punks</Footer.Link>
                    <Footer.Link href="https://nftkey.app/collections/binaryconkpunks/" target="_blank">Binary Punks</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                <Footer.Title>DeFi Friends</Footer.Title>
                    <Footer.Link href="https://spooky.fi/#/swap?outputCurrency=0xb715f8dce2f0e9b894c753711bd55ee3c04dca4e" target="_blank">Spooky Swap</Footer.Link>
                    <Footer.Link href="https://www.velocimeter.xyz/swap?to=0xb715f8dce2f0e9b894c753711bd55ee3c04dca4e" target="_blank">Velocimeter</Footer.Link>
                    <Footer.Link href="https://coinmarketcap.com/currencies/shibapoconk/" target="_blank">Coin Market Cap</Footer.Link>
                    <Footer.Link href="https://fantom.foundation/" target="_blank">Fantom Foundation</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                <Footer.Title>CONK</Footer.Title>
                    <Footer.Link href="/about">Manifesto</Footer.Link>
                    <Footer.Link href="/multi">Multisig</Footer.Link>
                    <Footer.Link href="https://medium.com/@shibapoconk/about" target="_blank">Medium Articles</Footer.Link>
                    <Footer.Link href="https://ftmscan.com/token/0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E" target="_blank">FTM Scan</Footer.Link>
                </Footer.Column>
                </Footer.Row>
            </Footer.Wrapper>

        </Footer>
    )
}