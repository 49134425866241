import React from 'react';
import './cards.css';
import CardItem from './CardItems';

function CardsPocong() {
  return (
        <div style={{
          width: '225px',
        }}>
          <ul className='cards__items'>
            <CardItem
              src='images/pocong.PNG'
              text='@fantompocong'
              label=''
              path='https://twitter.com/fantompocong'
            />
          </ul>
    </div>
  );
}

export default CardsPocong;