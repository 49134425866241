import React, { useState } from 'react';

const dataCollection = [
  {
    question: 'Who started $CONK?',
    answer: 'CONK was started by Pocong (@fantompocong) in 2023.  $CONK is a meme token created by an artist and treated as an art project.'
  }, {
    question: 'Who is part of the $CONK team?',
    answer: 'We are unsure of the answer to this question.  $CONK believes everyone is part of the $CONK team!'
  }, {
    question: 'Does CONK have a treasury? Or multisig wallet?',
    answer: 'CONK is controlled by two community driven wallets.  One for Treasury, and one for DeFi.  Click Multisig in the Header for more info.'
  }, {
    question: 'How can I help $CONK grow?',
    answer: 'Engagement.   Join Discord and Telegram.  Tweet.  Meme.  Spread the word.'
  }, {
    question: 'How do I buy $CONK?',
    answer: 'Check the on the home page of this website.  Our favourite places to buy $CONK are listed there.'
  }, {
    question: 'Can I stake $CONK?',
    answer: 'Our friends at Velocimeter offer a pool to Stake your $CONK LP.  More options are always being explored.'
  }, {
    question: 'Does $CONK have a fixed supply?',
    answer: 'No more $CONK will ever be generated.  With community burns, $CONK is deflationary.'
  }, {
    question: 'Does $CONK have any utility? Is there a roadmap?',
    answer: 'Not really, and not really.  Simply a pure Meme.'
  }, {
    question: 'Is there any burning of $CONK?',
    answer: 'Community events can decide to burn $CONK.  There is no protocol burn (or Tax) in place.'
  }, {
    question: 'How much $CONK is locked?',
    answer: '5% of supply is locked until the end of 2023.  POL is locked periodically on dexes.'
  }, {
    question: 'Is there any Tax for buying/selling of $CONK?',
    answer: 'Nope, and No.'
  }
];

function Accordion() {
  const [accordion, setActiveAccordion] = useState(-1);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return
    }
    setActiveAccordion(index);
  };

  return (
    <>
      <div className="container">
        <div>
          <span className="accordion__title"></span>
          <h1 className="accordion_h1">Let's answer some of your questions</h1>
        </div>
        <div className="accordion__faq">
          { dataCollection.map((item, index) =>
              <div key={index} onClick={() => toggleAccordion(index)}>
                <div className="accordion__faq-heading">
                  <h3 className={accordion === index ? "active" : ""}>{item.question}</h3>
                  <div>
                    {accordion === index ?
                      <span className="verticle">-</span> : <span className="horizental">+</span>}
                  </div>
                </div>
                <div><p className={accordion === index ? "active" : "inactive"} >{item.answer}</p></div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}

export default Accordion;